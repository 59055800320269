import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/VideoPlayer.module.css";

export default {
  "container": "VideoPlayer-module__container_hBOzya__",
  "continueBtn": "VideoPlayer-module__continueBtn_hBOzya__",
  "controls": "VideoPlayer-module__controls_hBOzya__",
  "currentTimeContainer": "VideoPlayer-module__currentTimeContainer_hBOzya__",
  "emptyStateContainer": "VideoPlayer-module__emptyStateContainer_hBOzya__",
  "emptyStateText": "VideoPlayer-module__emptyStateText_hBOzya__",
  "endTimeContainer": "VideoPlayer-module__endTimeContainer_hBOzya__",
  "finishedOverlay": "VideoPlayer-module__finishedOverlay_hBOzya__",
  "finishedTextContainer": "VideoPlayer-module__finishedTextContainer_hBOzya__",
  "hidden": "VideoPlayer-module__hidden_hBOzya__",
  "initialLoadingSpinner": "VideoPlayer-module__initialLoadingSpinner_hBOzya__",
  "muteBtn": "VideoPlayer-module__muteBtn_hBOzya__",
  "nextCourseText": "VideoPlayer-module__nextCourseText_hBOzya__",
  "overlay": "VideoPlayer-module__overlay_hBOzya__",
  "playBtn": "VideoPlayer-module__playBtn_hBOzya__",
  "playIcon": "VideoPlayer-module__playIcon_hBOzya__",
  "replayBtn": "VideoPlayer-module__replayBtn_hBOzya__",
  "smallPlayBtn": "VideoPlayer-module__smallPlayBtn_hBOzya__",
  "soundBtn": "VideoPlayer-module__soundBtn_hBOzya__",
  "timeContainer": "VideoPlayer-module__timeContainer_hBOzya__",
  "timeText": "VideoPlayer-module__timeText_hBOzya__",
  "timeline": "VideoPlayer-module__timeline_hBOzya__",
  "timelineContainer": "VideoPlayer-module__timelineContainer_hBOzya__",
  "upNextText": "VideoPlayer-module__upNextText_hBOzya__",
  "video": "VideoPlayer-module__video_hBOzya__",
  "videoLoading": "VideoPlayer-module__videoLoading_hBOzya__",
  "waitingSpinner": "VideoPlayer-module__waitingSpinner_hBOzya__",
  "waitingSpinnerContainer": "VideoPlayer-module__waitingSpinnerContainer_hBOzya__"
};
      