import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/EditWatchUnit.module.css";

export default {
  "addSegmentBtn": "EditWatchUnit-module__addSegmentBtn_T0P8UW__",
  "changesSavedIcon": "EditWatchUnit-module__changesSavedIcon_T0P8UW__",
  "changesSavedText": "EditWatchUnit-module__changesSavedText_T0P8UW__",
  "container": "EditWatchUnit-module__container_T0P8UW__",
  "editTextContainer": "EditWatchUnit-module__editTextContainer_T0P8UW__",
  "generateVideoInstructions": "EditWatchUnit-module__generateVideoInstructions_T0P8UW__",
  "generatingLoadingSpinner": "EditWatchUnit-module__generatingLoadingSpinner_T0P8UW__",
  "generatingText": "EditWatchUnit-module__generatingText_T0P8UW__",
  "inputLabel": "EditWatchUnit-module__inputLabel_T0P8UW__",
  "mainContent": "EditWatchUnit-module__mainContent_T0P8UW__",
  "requiredInputIndicator": "EditWatchUnit-module__requiredInputIndicator_T0P8UW__",
  "saveAndPreviewContainer": "EditWatchUnit-module__saveAndPreviewContainer_T0P8UW__",
  "saveStatusContainer": "EditWatchUnit-module__saveStatusContainer_T0P8UW__",
  "savingChangesIcon": "EditWatchUnit-module__savingChangesIcon_T0P8UW__",
  "savingChangesText": "EditWatchUnit-module__savingChangesText_T0P8UW__",
  "titleInput": "EditWatchUnit-module__titleInput_T0P8UW__",
  "videoGeneratedContainer": "EditWatchUnit-module__videoGeneratedContainer_T0P8UW__",
  "videoGeneratedIcon": "EditWatchUnit-module__videoGeneratedIcon_T0P8UW__",
  "videoGeneratedText": "EditWatchUnit-module__videoGeneratedText_T0P8UW__",
  "videoGeneratingContainer": "EditWatchUnit-module__videoGeneratingContainer_T0P8UW__",
  "videoPlayerContainer": "EditWatchUnit-module__videoPlayerContainer_T0P8UW__"
};
      