class AuthorizationManager {
  static MANAGER_DASHBOARD_PERMISSION = 'MANAGER_DASHBOARD';
  static EDIT_TRAINING_CONTENT = 'EDIT_TRAINING_CONTENT';

  hasManagerDashboardAccess(permissions) {
    return permissions.some(permission => permission.name === AuthorizationManager.MANAGER_DASHBOARD_PERMISSION);
  }

  hasEditTrainingContentAccess(permissions) {
    return permissions.some(permission => permission.name === AuthorizationManager.EDIT_TRAINING_CONTENT);
  }
}

export default AuthorizationManager;