// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import { ConfigProvider } from 'antd';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';

import Unauthenticated from './components/Unauthenticated';
import Login from './components/Login';
import SetInitialPassword from './components/SetInitialPassword';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordConfirmation from './components/ForgotPasswordConfirmation';
import ResetPassword from './components/ResetPassword';
import ResetPasswordSuccess from './components/ResetPasswordSuccess';
import App from './components/App';
import Overview from './components/Overview';
import Unit from './components/Unit';
import WatchUnit from './components/WatchUnit';
import PracticeScenario from './components/PracticeScenario';
import NewPresentationPracticeAttempt from './components/NewPresentationPracticeAttempt';
import PracticeIntroVideo from './components/PracticeIntroVideo';
import PracticeInstructions from './components/PracticeInstructions';
import PresentationPractice from './components/PresentationPractice';
import GatheringFeedbackPresentation from './components/GatheringFeedbackPresentation';
import CompletedPresentationPractice from './components/CompletedPresentationPractice';
import PracticeFeedback from './components/PracticeFeedback';
import EditWatchUnit from './components/EditWatchUnit';
import AllLearners from './components/managerDashboard/AllLearners';
import LearnerPracticeSubmissions from './components/managerDashboard/LearnerPracticeSubmissions';
import PracticeSubmission from './components/managerDashboard/PracticeSubmission';

const primaryColor = "#2e498b";
const secondaryColor = "#ffcc00";
const errorColor = "#e74b4a";
const successColor = "#1d9f7b";

const config = {
  token: {
    "colorPrimary": primaryColor,
    "colorInfo": secondaryColor,
    "colorError": errorColor,
    "colorSuccess": successColor,
    "colorWarning": secondaryColor,
    "colorLink": primaryColor,
    "borderRadius": 8,
    "fontFamily": "'Open Sans', sans-serif"
  },
  components: {
    "Typography": {
      "fontFamily": "'Open Sans', sans-serif"
    },
    "Button": {
      "primaryShadow": "0 0px 0 rgba(0, 0, 0, 0.02)",
      "dangerShadow": "0 0px 0 rgba(0, 0, 0, 0.02)",
      "defaultShadow": "0 0px 0 rgba(0, 0, 0, 0.02)",
      "fontFamily": "'Open Sans', sans-serif",
    },
    "Spin": {
      "dotSizeLG": 120,
      "colorPrimary": "#ffffff",
    },
    "Layout": {
      "headerBg": secondaryColor,
    },
    "Menu": {
      "itemBg": secondaryColor,
      "colorText": primaryColor
    }
  }
};

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <ConfigProvider theme={config}>
      <Router>
        <Routes>
          <Route element={<Unauthenticated />}>
            <Route path="login" element={<Login />} />
            <Route path="set-initial-password" element={<SetInitialPassword />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="forgot-password-confirmation" element={<ForgotPasswordConfirmation />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="reset-password-success" element={<ResetPasswordSuccess />} />
          </Route>
          <Route element={<App />}>
            <Route index element={<Overview />} />
            <Route path="/subtopic/:subtopicToken" element={<Unit />}>
              <Route path="watch/:unitToken" element={<WatchUnit />} />
              <Route path="practice/:unitToken" element={<PracticeScenario />}>
                <Route 
                  index
                  element={<NewPresentationPracticeAttempt />}
                />
                <Route 
                  path="primary-video"
                  element={<PracticeIntroVideo />}
                />
                <Route 
                  path="instructions"
                  element={<PracticeInstructions />}
                />
                <Route 
                  path="attempt/:practiceAttemptToken"
                  element={<PresentationPractice />}
                />
                <Route 
                  path="session-completed/:feedbackToken"
                  element={<CompletedPresentationPractice />}
                />
                <Route 
                  path="gathering-feedback/:feedbackToken"
                  element={<GatheringFeedbackPresentation />}
                />
                <Route 
                  path="transcript-summary/:feedbackToken"
                  element={<PracticeFeedback />}
                />
              </Route>
            </Route>
            <Route 
              path="/subtopic/:subtopicToken/watch/:unitToken/edit" 
              element={<EditWatchUnit />} 
            />
            <Route 
              path="/manager-dashboard" 
              element={<AllLearners />} 
            />
            <Route 
              path="/manager-dashboard/learner/:learnerToken/attempt/:practiceAttemptToken" 
              element={<PracticeSubmission />} 
            />
            <Route 
              path="/manager-dashboard/learner/:learnerToken" 
              element={<LearnerPracticeSubmissions />} 
            />
          </Route>
        </Routes>
      </Router>
    </ConfigProvider>, document.getElementById('root'));
});
