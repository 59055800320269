import React, { useState, useRef } from 'react';
import { Typography, Spin, Button } from 'antd';
import { 
  PlayCircleFilled,
  SoundFilled,
  MutedFilled,
  PauseOutlined,
  LoadingOutlined,
  CaretRightOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

import styles from '../../stylesheets/VideoPlayer.module.css';

const INITIAL_LOADING = 'INITIAL_LOADING';
const NOT_STARTED = 'NOT_STARTED';
const PLAYING = 'PLAYING';
const WAITING = 'WAITING';
const PAUSED = 'PAUSED';
const FINISHED = 'FINISHED';

const VideoPlayer = (props) => {
  const videoRef = useRef(null);

  const [videoState, setVideoState] = useState(INITIAL_LOADING);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  const handlePlay = () => {
    videoRef.current.play();
    setVideoState(PLAYING);
  }

  const handleReplay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setCurrentTime(0);
    setVideoState(PLAYING);
  }

  const handleVideoLoaded = () => {
    setVideoState(NOT_STARTED);
  }

  const handleWaiting = () => {
    setVideoState(WAITING);
  }

  const handlePlaying = () => {
    setVideoState(PLAYING);
  }

  const handleVideoEnd = () => {
    setVideoState(FINISHED);
    props.handleVideoComplete();
  }

  const handlePause = () => {
    videoRef.current.pause();
    setVideoState(PAUSED);
  }

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };
 
  const videoControlPrimaryButton = () => {
    if (videoState == NOT_STARTED || videoState == PAUSED) {
      return(
        <CaretRightOutlined 
          className={styles.smallPlayBtn} 
          onClick={handlePlay}
        />
      );
    } else if (videoState == FINISHED) {
      return(
        <ReloadOutlined 
          className={styles.replayBtn}
          onClick={handleReplay}
        />
      )
    } else {
      return(
        <PauseOutlined 
          className={styles.smallPlayBtn} 
          onClick={handlePause}
        />
      );
    }
  }

  const videoLoadingClassName = () => {
    if (videoState == INITIAL_LOADING) {
      return styles.videoLoading;
    } else {
      return styles.hidden;
    }
  }

  const videoContainerClassName = () => {
    if (videoState == INITIAL_LOADING) {
      return styles.hidden;
    } else {
      return styles.video;
    }
  }

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  const displayTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds) % 60;
    
    const paddedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    
    return `${minutes}:${paddedSeconds}`;
  }

  const handleTimelineChange = (event) => {
    const newTime = (event.target.value / 100) * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);

    if (newTime < duration && videoState === FINISHED) {
      setVideoState(PAUSED);
    } else if (newTime == duration && videoState === PAUSED) {
      setVideoState(FINISHED);
      props.handleVideoComplete();
    }
  }

  const displayFinishedOverlay = () => {
    if (videoState === FINISHED) {
      if (props.showNextUnitOverlay) {
        return(
          <div className={styles.finishedOverlay}>
            <div className={styles.finishedTextContainer} key='finishedTextContainer'>
              <Text className={styles.upNextText}>Up Next</Text>
              <br/>
              <Text className={styles.nextCourseText}>{props.nextUnitTitle}</Text>
            </div>
            <Button 
              type='primary'
              className={styles.continueBtn}
              size='large'
              onClick={props.handleNextUnitClick}
              key='continueButton'
            >
              Continue
            </Button>
          </div>
        );
      } else {
        return <div className={styles.finishedOverlay} />;
      }
    }
  }

  if (props.videoUrl === null) {
    return(
      <div className={styles.emptyStateContainer}>
        <div className={styles.emptyStateText}>
          No video to play
        </div>
      </div>
    )
  }

  return(
    <div>
      <div className={styles.container}>
        {displayFinishedOverlay()}
        {
          videoState == NOT_STARTED &&
            <div>
              <div className={styles.overlay} />
              <div className={styles.playBtn} />
              <PlayCircleFilled 
                className={styles.playIcon} 
                onClick={handlePlay}
              />
            </div>
        }
        <div className={videoLoadingClassName()}>
          <Spin 
            indicator={<LoadingOutlined className={styles.initialLoadingSpinner} spin />} 
          />
        </div>
        <video
          ref={videoRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onLoadedData={handleVideoLoaded}
          onWaiting={handleWaiting}
          onPlaying={handlePlaying}
          onEnded={handleVideoEnd}
          controls={false}
          className={videoContainerClassName()}
          crossOrigin='anonymous'
        >
          <source src={props.videoUrl} type="video/mp4" />
          <track
            default
            kind='captions'
            src={props.captionsUrl}
            srcLang='en'
          />
        </video>
        {
          videoState === WAITING &&
            <Spin 
              className={styles.waitingSpinnerContainer}
              indicator={<LoadingOutlined className={styles.waitingSpinner} spin />} 
            />
        }
      </div>
      <div className={styles.controls}>
        {videoControlPrimaryButton()}
        {
          isMuted ?
            <MutedFilled 
              className={styles.muteBtn}
              onClick={toggleMute}
            />
            :
            <SoundFilled 
              className={styles.soundBtn}
              onClick={toggleMute}
            />
        }
        <div className={styles.timeContainer}>
          <div className={styles.currentTimeContainer}>
            <Text className={styles.timeText}>
              {displayTime(currentTime)}
            </Text>
          </div>
          <div className={styles.timelineContainer}>
            <input
              type='range'
              min='0'
              max='100'
              value={(currentTime / duration) * 100 || 0}
              onChange={handleTimelineChange}
              className={styles.timeline}
            />
          </div>
          <div className={styles.endTimeContainer}>
            <Text className={styles.timeText}>
              {displayTime(duration)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
};

export default VideoPlayer;