import { DevicePermissionsState } from '../lib/constants';

const GRANTED = 'granted';
const DENIED = 'denied';

class DevicePermissions {
  constructor(deviceManager) {
    this.microphonePermission = 'microphone';
    this.cameraPermission = 'camera';
    this.deviceManager = deviceManager;
  }

  async checkPermission(permissionName) {
    try {
      const status = await this.deviceManager.permission(permissionName);
      return status.state;
    } catch (error) {
      console.error(`Error checking ${permissionName} permission:`, error);
      return DENIED;
    }
  }

  async permissionStateWithoutRequest() {
    const micState = await this.checkPermission(this.microphonePermission);
    const camState = await this.checkPermission(this.cameraPermission);

    if (this.isPermissionsGranted(micState, camState)) {
      try {
        await this.deviceManager.getUserMedia({ audio: true, video: true });
        return DevicePermissionsState.GRANTED;
      } catch (error) {
        console.info(`OS device permissions denied error=${error.name} message=${error.message}`);
        return DevicePermissionsState.OS_DENIED;
      }
    }
    if (this.isEitherPermissionDenied(micState, camState)) {
      return DevicePermissionsState.BROWSER_DENIED;
    }

    return DevicePermissionsState.ASK;
  }

  isPermissionsGranted(micState, camState) {
    return micState === GRANTED && camState === GRANTED;
  }

  isEitherPermissionDenied(micState, camState) {
    return micState === DENIED || camState === DENIED;
  }

  async permissionStateWithRequest() {
    try {
      await this.deviceManager.getUserMedia({ audio: true, video: true });
      return DevicePermissionsState.GRANTED;
    } catch (error) {
      console.info(`Device permissions denied  error=${error.name} message=${error.message}`);
      const micState = await this.checkPermission(this.microphonePermission);
      const camState = await this.checkPermission(this.cameraPermission);

      if (micState === GRANTED && camState === GRANTED) {
        return DevicePermissionsState.OS_DENIED;
      } else {
        return DevicePermissionsState.BROWSER_DENIED;
      }
    }
  }
}

export default DevicePermissions;
export { GRANTED, DENIED };